
import { createMenuByHeaders } from "@/utils/menuByHeaders"
import StickyMenu from "@/components/StickyMenu/StickyMenu.vue"
import ScrollTop from "@/components/ScrollTop/ScrollTop.vue"
import { Options, Vue } from "vue-class-component"
import SurveyTermsContent from "@/components/SurveyTermsContent.vue"

@Options({
  components: {
    StickyMenu,
    ScrollTop,
    SurveyTermsContent
  }
})

export default class SurveyTerms extends Vue {
  mounted(): void {
    createMenuByHeaders({
      menuId: "menu",
      menuClassLinks: ".sticky-menu a",
      headerSelector: ".terms h2"
    })
  }
}
